import classNames from 'classnames';
import { Menu, MenuItem, RouterProvider } from 'react-aria-components';
import { cls } from '../utils';

export { RouterProvider };

type NavigationMenuProps = Parameters<typeof Menu>[0];
export function NavigationMenu({ className, children, ...props }: NavigationMenuProps) {
  return (
    <Menu className={classNames(className)} aria-label="Navigation Menu" {...props}>
      {children}
    </Menu>
  );
}

type NavigationItemProps = Parameters<typeof MenuItem>[0] & {
  selected?: boolean;
};
export function NavigationItem({
  id, className, children, selected = false, ...props
}: NavigationItemProps) {
  return (
    <MenuItem
      id={id}
      className={cls(
        'font-medium text-lg rounded transition hover:bg-[#E1E1E1] hover:cursor-pointer px-3 py-1.5 block w-full text-black no-underline',
        'data-[disabled]:hover:bg-transparent data-[disabled]:opacity-50',
        selected && 'font-semibold',
        typeof className === 'string' && className,
      )}
      {...props}
    >
      {children}
    </MenuItem>
  );
}
