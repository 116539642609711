/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import React from 'react';
import { Control, Controller, UseFormRegisterReturn } from 'react-hook-form';
import { Switch as AntSwitch, SwitchProps as AntSwitchProps } from 'antd';
import selectStyles from './select.module.scss';

interface SwitchProps extends AntSwitchProps {
  label?: string,
  formRegister: UseFormRegisterReturn,
  formControl: any,
  className?: string,
}

const Switch: React.FC<SwitchProps> = ({
  label = undefined, formRegister, formControl, className = '', ...props
}) => (
  <div className={classNames(className, 'otmow-switch-input')}>
    {label && (<span className={classNames(selectStyles.label, 'block mb-1')}>{label}</span>)}
    <Controller
      name={formRegister.name}
      control={formControl as Control}
      render={({ field }) => (
        <AntSwitch
          className={field.value ? 'bg-blue-500' : 'bg-stone-300'}
          {...field}
          {...props}
        />
      )}
    />
  </div>
);

export default Switch;
