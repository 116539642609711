import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';

// This proxy is used to transform a dictionary into something that accepts nested indexing
// for example instead of just myObject['key'] or myObject.key, if 'key' is a dictionary you can also do
// myObject['key.subkey']
export const nestedIndexingProxy = {
  /* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment */
  get(target:Record<string, any>, name:string) {
    const agenda = name.split('.');
    let result = target;
    while (result && agenda.length) result = result[agenda.shift()!];
    return result;
  },
  set(target:Record<string, any>, _name:string, value:any) {
    const agenda = _name.split('.');
    const [name] = agenda.splice(-1);
    if (!name) throw new Error('Invalid key');
    let result = target;
    while (agenda.length) {
      const current = agenda.shift()!;
      result[current] = result[current] || {};
      result = result[current];
    }
    result[name] = value;
    return true;
  },
  /* eslint-enable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment */
};

export function cls(...names: Parameters<typeof classNames>) {
  return twMerge(classNames(names));
}
