'use client';

import { Button as AntButton, ButtonProps as AntButtonProps } from 'antd';
import classNames from 'classnames';
import React from 'react';

interface ButtonProps extends AntButtonProps {
  className?: string;
  color?: string;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({
  children = undefined, className = '', type = 'default', color = '', ...props
}, ref) => (
  <AntButton
    ref={ref}
    type={type}
    className={classNames(`color-pair-${color} h-auto`, props.disabled && color && 'opacity-20', className)}
    {...props}
  >
    {children}
  </AntButton>
));

// eslint-disable-next-line import/prefer-default-export
export { Button };
