import { RadioGroupProps as AntRadioGroupProps, CheckboxOptionType, Radio } from 'antd';
import classNames from 'classnames';
import i18next from 'i18next';
import React from 'react';
import { Control, Controller, UseFormRegisterReturn } from 'react-hook-form';
import radioInputStyles from './radioInput.module.scss';

interface RadioGroupWithOptionsProps extends AntRadioGroupProps {
  formRegister: UseFormRegisterReturn,
  formControl: any;
  options: CheckboxOptionType[];
  className?: string;
  label?: string,
}

const RadioGroupWithOptions = ({
  formRegister, formControl, options, className = '', label = undefined, ...props
}: RadioGroupWithOptionsProps) => (
  <div className={classNames(radioInputStyles.radioGroupContainer, 'otmow-radio-group-with-options', className)}>
    {label && (<span className={radioInputStyles.label}>{label}</span>)}
    <Controller
      name={formRegister.name}
      control={formControl as Control}
      render={({ field }) => (
        <Radio.Group {...field} className={classNames(radioInputStyles.radioGroup)} {...props}>
          {options.map((option, index) => (
            // eslint-disable-next-line react/no-array-index-key, @typescript-eslint/no-unsafe-assignment
            <Radio value={option.value} key={index}>
              {option.label}
            </Radio>
          ))}
        </Radio.Group>
      )}
    />
  </div>
);

interface RadioGroupProps extends AntRadioGroupProps {
  formRegister: UseFormRegisterReturn,
  formControl: any;
  className?: string;
  radioClassname?: string;
  label?: string,
  children?: React.ReactNode;
}

const RadioGroup: React.FC<RadioGroupProps> = ({
  formRegister, formControl, className = '', radioClassname = '', label = null, children = undefined, ...props
}) => (
  <div className={classNames('otmow-radio-group', className)}>
    {label && (<span className={classNames(radioInputStyles.label, radioClassname)}>{label}</span>)}
    <Controller
      name={formRegister.name}
      control={formControl as Control}
      render={({ field }) => (
        <Radio.Group
          className={radioClassname}
          {...field}
          {...props}
        >
          {children}
        </Radio.Group>
      )}
    />
  </div>
);

interface RadioYNProps extends AntRadioGroupProps {
  label?: string;
  formRegister: UseFormRegisterReturn;
  formControl: any;
}

const RadioYN: React.FC<RadioYNProps> = ({
  label = undefined, formRegister, formControl, ...props
}) => {
  const { t } = i18next;
  const options: CheckboxOptionType[] = [
    { label: t('Yes'), value: true },
    { label: t('No'), value: false },
  ];
  return (
    <RadioGroup
      label={label}
      formRegister={formRegister}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      formControl={formControl}
      {...props}
      options={options}
    />
  );
};

export { Radio, RadioGroup, RadioGroupWithOptions, RadioYN };
