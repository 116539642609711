'use client';

import React from 'react';
import { Collapse as AntCollapse, CollapseProps } from 'antd';

const Collapse: React.FC<CollapseProps> = ({ ...props }) => (
  <AntCollapse expandIconPosition="end" {...props} />
);

export default Collapse;
