import { TSpan } from '.';
import { cls } from '../utils';

type TimelineItemProps = React.PropsWithChildren<{
  timestamp?: string;
  completed: boolean;
  icon?: React.ReactNode;
}>;
export function TimelineItem({ timestamp, completed, children, icon = undefined }: TimelineItemProps) {
  const date = timestamp ? new Date(timestamp).toLocaleString() : null;

  return (
    <li className="grid grid-cols-[auto_1fr] mb-3 last-of-type:mb-0 gap-y-1 gap-x-4 items-center">
      <div className="justify-self-center">
        <div className={cls('border border-[#dedede] rounded-full aspect-square w-[24px] flex items-center justify-center', completed && 'bg-[#04AE2A] border-none')}>
          {icon
            ? <span className={cls('text-base invisible', completed && 'text-white visible')}>{icon}</span>
            : <i className={cls('material-symbols-outlined text-base invisible', completed && 'text-white visible')}>done</i>}
        </div>
      </div>
      <div className={cls('text-[#C7C7C7] font-medium', completed && 'text-black font-semibold')}>{children}</div>
      {completed && date ? (
        <>
          <div />
          <div className="text-sm font-medium text-[#7A7A7A]">{date}</div>
        </>
      ) : null}

    </li>
  );
}

type Props = React.PropsWithChildren<{
  className?: string;
  totalSteps: number;
  completedSteps: number;
}>;
export function Timeline({ totalSteps, completedSteps, children, className }: Props) {
  const ratio = completedSteps / totalSteps;
  const percentage = `${ratio * 100}%`;

  return (
    <div className={cls('bg-[#FAFAFA] rounded-2xl py-6 px-5', className)}>
      <header className="text-xl font-bold"><TSpan>Timeline</TSpan></header>
      <div className="flex gap-4 justify-between items-center py-5">
        <span>
          {completedSteps}
          /
          {totalSteps}
          {' '}
          <TSpan>steps completed</TSpan>
        </span>
        <div className="bg-[#E1E1E1] h-1 w-16 rounded">
          <div className="h-1 rounded bg-[#2C71F0]" style={{ width: percentage }} />
        </div>
      </div>
      <ul className="mb-0 p-0">
        {children}
      </ul>
    </div>
  );
}