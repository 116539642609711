import { Checkbox, CheckboxProps } from 'antd';
import classNames from 'classnames';
import React from 'react';
import {
  Control,
  Controller,
  UseFormRegisterReturn,
} from 'react-hook-form';
import checkboxStyles from './checkbox.module.scss';

interface CheckboxInputProps extends CheckboxProps {
  label: string|React.ReactNode,
  formRegister: UseFormRegisterReturn,
  formControl: any,
  className?: string,
  status?: string,
  alignTop?: boolean,
}

const CheckboxInput: React.FC<CheckboxInputProps> = ({
  label, formRegister, formControl, className = '', status = '', alignTop = false, ...props
}) => (
  <Controller
    name={formRegister.name}
    control={formControl as Control}
    render={({ field }) => (
      <Checkbox
        className={classNames(className, checkboxStyles[status], checkboxStyles[alignTop ? 'alignTop' : ''])}
        name={field.name}
        checked={field.value as boolean}
        onChange={(e) => field.onChange(e.target.checked)}
        ref={field.ref}
        {...props}
      >
        {label}

      </Checkbox>
    )}
  />
);

export default CheckboxInput;
