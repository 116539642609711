/* eslint-disable react/jsx-props-no-spreading */
import { Select as AntSelect, SelectProps as AntSelectProps } from 'antd';
import classNames from 'classnames';
import React from 'react';
import { Control, Controller, UseFormRegisterReturn } from 'react-hook-form';
import selectStyles from './select.module.scss';

type OptionType = AntSelectProps['options'];
interface SelectProps extends AntSelectProps {
  label?: string,
  options: OptionType,
  // changes whether the value of nothing selected is '' or []
  multiple?: boolean,
  formRegister: UseFormRegisterReturn,
  formControl: any,
  className?: string,
  formatter?: (value: string) => string
}

const Select: React.FC<SelectProps> = ({
  label = undefined, multiple = false, options, formRegister, formControl, formatter, className = '', ...props
}) => (
  <div className={classNames(selectStyles.dropdown, className, 'otmow-dropdown-input')}>
    {label && (<span className={selectStyles.label}>{label}</span>)}
    <Controller
      name={formRegister.name}
      control={formControl as Control}
      render={({ field: _field }) => {
        const originalOnChange = _field.onChange;
        const field = {
          ..._field,
          onChange: (_updatedValue: string | string[]) => {
            let updatedValue = _updatedValue;
            if (formatter && Array.isArray(_updatedValue)) {
              updatedValue = _updatedValue.map(formatter);
            } else if (formatter) {
              updatedValue = formatter(_updatedValue as string);
            }

            originalOnChange(updatedValue);
          },
        };

        return (
          <AntSelect
            className={selectStyles.select}
            {...(multiple ? { mode: 'multiple' } : {})}
            {...field}
            options={options}
            allowClear
            {...props}
          />
        );
      }}
    />
  </div>
);

export default Select;
