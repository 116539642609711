import { Slider, SliderSingleProps } from 'antd';
import classNames from 'classnames';
import React from 'react';
import { Control, Controller, UseFormRegisterReturn } from 'react-hook-form';
import styles from './sliderInput.module.scss';

interface SliderInputProps extends SliderSingleProps {
  label?: string,
  formRegister: UseFormRegisterReturn,
  formControl: any,
  min: number,
  className?: string
}

const SliderInput: React.FC<SliderInputProps> = ({
  label = '', formRegister, formControl, className = '', ...props
}) => (
  <div className={classNames(styles.sliderInput, className, 'otmow-slider-input')}>
    <span className={styles.label}>{label}</span>
    <Controller
      name={formRegister.name}
      control={formControl as Control}
      {...props}
      render={({
        field,
      }) => (
        <Slider {...field} {...props} />
      )}
    />
  </div>
);

export default SliderInput;
