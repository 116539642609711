import { Modal as AntdModal, ModalProps as AntdModalProps } from 'antd';
import classNames from 'classnames';
import React from 'react';
import { Button } from './Button';
import styles from './modal.module.scss';

interface ModalProps extends AntdModalProps {
  onPrevPage?: (() => void) | null;
  onNextPage?: (() => void) | null;
  sideModal?: boolean;
  fullHeight?: boolean;
  fullWidth?: boolean;
  title?: string;
  onCancel?: () => void;
  isLoading?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  footer: _footer, onNextPage = null, onPrevPage = null, title = '', sideModal = false, fullHeight = false, fullWidth = false, onCancel = undefined, isLoading = false, ...props
}) => {
  let footer = _footer;
  if (footer === undefined && (onNextPage || onPrevPage)) {
    footer = (
      <>
        {onPrevPage ? (
          <Button onClick={onPrevPage}>{props.cancelText || 'Back'}</Button>
        ) : null}
        {onNextPage ? (
          <Button color="blue" className="ml-auto" type="primary" loading={isLoading} onClick={onNextPage}>{props.okText || 'Next'}</Button>
        ) : null}
      </>
    );
  }

  const headerTitle = (
    <>
      {(onCancel || onPrevPage) && (
        <div className="flex mb-2 justify-between flex-row">
          {onPrevPage && <Button size="large" onClick={onPrevPage} className={styles.closeButton} shape="circle" icon={<span className="material-symbols-outlined text-base">arrow_back_ios_new</span>} />}
          {!onPrevPage && (<div className="text-2xl font-semibold">{title}</div>)}
          {onCancel && <Button size="large" onClick={onCancel} className={styles.closeButton} shape="circle" icon={<span className="material-symbols-outlined text-2xl">close</span>} />}
        </div>
      )}
      {onPrevPage && <div className="text-2xl font-semibold">{title}</div>}
    </>
  );
  return (
    <AntdModal
      closeIcon={false}
      title={headerTitle}
      width={fullWidth ? 'calc(100vw - 50px)' : undefined}
      className={classNames(styles.modal, sideModal && styles.sideModal, fullHeight && styles.fullHeight, fullWidth && styles.fullWidth)}
      footer={footer}
      onCancel={onCancel}
      {...props}
    />
  );
};

export default Modal;
