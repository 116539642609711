'use client';

import { FileAddOutlined } from '@ant-design/icons';
import { Upload as AntUpload, UploadProps as AntUploadProps } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/es/upload';
import classNames from 'classnames';
import React from 'react';
import { Control, Controller, UseFormRegisterReturn } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { Button } from './Button';
import styles from './upload.module.scss';
import { cls } from '../utils';

export interface DisplayFile<T = any> extends UploadFile<T> {
  message: string;
}

interface UploadProps extends AntUploadProps {
  label?: string,
  formRegister: UseFormRegisterReturn,
  formControl: any,
  className?: string,
  fileList?: DisplayFile[],
  manual?: boolean,
  noRounding?: boolean,
  onRemove?: (file: UploadFile) => Promise<boolean | void>,
}

const Upload: React.FC<UploadProps> = ({
  formRegister, formControl, className = '', fileList = [], ...props
}) => (
  <div className={className}>
    <Controller
      name={formRegister.name}
      control={formControl as Control}
      render={({ field }) => {
        const onChange = (info: AntUploadProps) => {
          field.onChange(info.fileList);
        };

        return (<AntUpload name={field.name} onChange={onChange} fileList={fileList} {...props} />);
      }}
    />
  </div>
);

const removeIcon = (file: DisplayFile) => (file.status === 'error' ? (
  <div className="text-red-600 flex justify-center">
    <span className="material-symbols-outlined text-sm mr-1">info</span>
    <span className="text-sm">{file.message}</span>
  </div>
) : null);

const DraggerUpload: React.FC<UploadProps> = ({
  formRegister, formControl, className = '', label = '', fileList = [], ...props
}) => (
  <Controller
    name={formRegister.name}
    control={formControl}
    render={({ field }) => {
      const onChange = (info: UploadChangeParam<UploadFile>) => {
        field.onChange(info.fileList);
      };

      return (
        <label className={classNames(className, styles.draggerInput, 'otmow-dragger-input')}>
          {label && (<p className={classNames(styles.label, 'text-base')}>{label}</p>)}
          {fileList.filter((x) => x.status === 'done').length > 0 || (props.manual && fileList.length > 0) ? (
            <AntUpload.Dragger openFileDialogOnClick={false} name={field.name} className={styles.hideUpload} onChange={onChange} fileList={fileList} {...props}>
              <div className="flex items-center w-full">
                <span className="material-symbols-outlined mr-2 ml-4">description</span>
                <span className="truncate">{fileList[0]!.name}</span>
                {/* TODO: the backend does not yet allow deleting files */}
                <Button
                  type="text"
                  className="ml-auto"
                  onClick={(e) => {
                    if (props.onRemove) {
                      e.stopPropagation();
                      props.onRemove(fileList[0]!).catch(() => { });
                    }
                  }}
                >
                  <span className="material-symbols-outlined text-sm">close</span>
                </Button>
              </div>
            </AntUpload.Dragger>
          ) : (
            <AntUpload.Dragger
              showUploadList={{ removeIcon: (file) => removeIcon(file as DisplayFile) }}
              className={cls(styles.upload, props.noRounding && styles.noRound)}
              name={field.name}
              onChange={onChange}
              openFileDialogOnClick={false}
              fileList={fileList}
              onPreview={props.onPreview}
              {...props}
            >
              <div className="flex justify-center w-full gap-3 items-center">
                <div>
                  <FileAddOutlined className="text-3xl text-blue-500" />
                </div>
                <div className="text-left leading-tight text-base">
                  <Trans i18nKey="dragAndDropMessage" className="text-stone-900">
                    Drag & drop or
                    {' '}
                    <span className="text-blue-500">choose file</span>
                    {' '}
                    to upload
                  </Trans>
                  <div className="text-stone-900 opacity-60 text-sm">
                    <Trans i18nKey="acceptedFileFormats" />
                    : JPG, PNG, PDF
                  </div>
                </div>
              </div>
            </AntUpload.Dragger>
          )}
        </label>
      );
    }}
  />
);

export { DraggerUpload, Upload };
