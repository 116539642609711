'use client';

import { Table as AntTable, TableProps, Empty } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import tableStyles from './table.module.scss';

const Table = <T extends object>({ className = '', ...props }: TableProps<T>) => {
  const { t } = useTranslation();

  return (
    <AntTable
      className={classNames(tableStyles.table, className)}
      locale={{
        emptyText: <Empty description={t('No Data')} image={Empty.PRESENTED_IMAGE_SIMPLE} />,
      }}
      {...props}
    />
  );
};

export default Table;
