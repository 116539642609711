'use client';

import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import React from 'react';

// the elements here are versions of native html elements that automatically will translate
// thier contents using locizize to the user's chosen language
interface TDivProps extends React.HTMLAttributes<HTMLDivElement> {
  text?: string;
  children?: string;
  t?: TFunction;
}

export const TDiv: React.FC<TDivProps> = ({ children, text, t: tProp, ...props }) => {
  const rawText = text || children;
  if (!rawText) return null;
  const t = tProp ?? useTranslation().t;
  return (
    <div {...props}>
      {t(rawText)}
    </div>
  );
};

interface TSpanProps extends React.HTMLAttributes<HTMLSpanElement> {
  children?: string;
  text?: string;
  t?: TFunction;
}

export const TSpan: React.FC<TSpanProps> = ({ children, text, t: tProp, ...props }) => {
  const rawText = text || children;
  if (!rawText) return null;
  const t = tProp ?? useTranslation().t;
  return (
    <span {...props}>
      {t(rawText)}
    </span>
  );
};
