'use client';

import { Input as AntInput, InputNumber as AntInputNumber, InputNumberProps, InputProps, ConfigProvider } from 'antd';
import { TextAreaProps } from 'antd/lib/input/';
import classNames from 'classnames';
import React from 'react';
import { Control, Controller, UseFormRegisterReturn } from 'react-hook-form';
import ReactPhoneInput from 'react-phone-number-input';
import colors from 'tailwindcss/colors';
import textInputStyles from './textInput.module.scss';

interface TextInputProps {
  label?: string | React.ReactNode,
  formRegister: UseFormRegisterReturn,
  formControl: any,
  className?: string,
  inputClassName?: string,
  formatter?: (value: string) => string
  status?: InputProps['status'],
  readOnly?: boolean,
}

const commonComponent: React.FC<TextInputProps> = ({
  label, formRegister, formControl, className = '', inputClassName = '', formatter, ...props
}, type: string) => (
  <label className={classNames(textInputStyles.textInput, className, 'otmow-text-input')}>
    <span className="text-sm text-black/80 mb-1">{label}</span>

    <ConfigProvider
      theme={{
        components: {
          InputNumber: {
            colorBgContainer: props.readOnly ? colors.neutral[100] : '',
          },
        },
      }}
    >
      <Controller
        name={formRegister.name}
        control={formControl as Control}
        render={({ field: _field }) => {
          const originalOnChange = _field.onChange;
          let field = _field;
          if (['text', 'password', 'search', 'textarea'].includes(type)) {
            field = {
              ..._field,
              onChange: (e: { target: { value: string } }) => {
                if (formatter) {
                  e.target.value = formatter(e.target.value);
                }
                originalOnChange(e);
              },
            };
          }

          if (type === 'text') return (<AntInput className={inputClassName} {...props} {...field} />);
          if (type === 'password') return (<AntInput.Password className={inputClassName} {...props} {...field} />);
          if (type === 'search') return (<AntInput.Search className={inputClassName} {...props} {...field} />);
          if (type === 'textarea') return (<AntInput.TextArea className={inputClassName} {...props} {...field} />);
          if (type === 'phone') return (<ReactPhoneInput className={inputClassName} {...props} {...field} />);
          if (type === 'number') return (<AntInputNumber className={classNames('w-full', inputClassName)} formatter={formatter} {...props} {...field} />);
          throw Error('Invalid type');
        }}
      />
    </ConfigProvider>

  </label>
);

const TextInput: React.FC<TextInputProps & InputProps> = (options) => commonComponent(options, 'text');
const PasswordInput: React.FC<TextInputProps & InputProps> = (options) => commonComponent(options, 'password');
const SearchInput: React.FC<TextInputProps> = (options) => commonComponent(options, 'search');
const TextAreaInput: React.FC<TextInputProps & TextAreaProps> = (options) => commonComponent(options, 'textarea');
const PhoneInput: React.FC<TextInputProps> = (options) => commonComponent(options, 'phone');
const NumberInput: React.FC<TextInputProps & InputNumberProps> = (options) => commonComponent(options, 'number');

export { NumberInput, PasswordInput, PhoneInput, SearchInput, TextAreaInput, TextInput };
