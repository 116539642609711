/* eslint-disable react/jsx-props-no-spreading */
import { Segmented as AntSegmented, SegmentedProps as AntSegmentedProps } from 'antd';
import classNames from 'classnames';
import React from 'react';
import { Control, Controller, UseFormRegisterReturn } from 'react-hook-form';
import segmentedStyles from './segmented.module.scss';

interface SegmentedProps extends AntSegmentedProps {
  label?: string,
  formRegister: UseFormRegisterReturn,
  formControl: any,
}

const Select: React.FC<SegmentedProps> = ({
  label = undefined, formRegister, formControl, className = '', ...props
}) => (
  <div className={classNames(segmentedStyles.segmented, className, 'otmow-dropdown-input')}>
    {label && (<span className={segmentedStyles.label}>{label}</span>)}
    <Controller
      name={formRegister.name}
      control={formControl as Control}
      render={({ field }) => (
        <AntSegmented
          {...field}
          {...props}
        />
      )}
    />
  </div>
);

export default Select;
