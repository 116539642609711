import classNames from 'classnames';
import React from 'react';
import textDisplayStyles from './textDisplay.module.scss';

interface TextInputProps {
  label: string,
  value: React.ReactNode,
  className?: string,
}

const TextDisplay: React.FC<TextInputProps> = ({ label, value, className = '' }) => (
  <div className={classNames(textDisplayStyles.textDisplay, className)}>
    <p>{value}</p>
    <div className={classNames(textDisplayStyles.overlay, textDisplayStyles.small)}>
      <span>{label}</span>
    </div>
  </div>
);

export default TextDisplay;
